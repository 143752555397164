<router-outlet></router-outlet>

<div class="message"
     [ngClass]="{'bg-danger' : errorMessage, 'bg-success' : !errorMessage, 'show' : state==='show', 'hide' : state==='hide'}">
  <div class="col-sm-2" style="height: 100%; border-right: 2px solid rgba(208, 191, 191, 0.19); position: relative; float: left;">
    <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);">
      <i *ngIf="!errorMessage" class="fa fa-check-circle" style="color: #10b64e; font-size: 30px;"></i>
    </div>
    <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);">
      <i *ngIf="errorMessage" class="fa fa-exclamation-circle" style="color: #ff5619; font-size: 30px;"></i>
    </div>
  </div>
  <div class="col-sm-10" style="height: 100%; float: left; position: relative">
    <div class="messageText text-center">
      {{textMessage}}
    </div>
  </div>
</div>
