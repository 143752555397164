<app-houses-list-search (changed)="getHousesSearch($event)"></app-houses-list-search>

<div class="table-responsive" style="margin-bottom: 20px">
  <table class="table table-bordered table-hover">
    <thead>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th>Тип</th>
      <th>Обл.</th>
      <th>Район/напр</th>
      <th>Адрес</th>
      <th>Крыша</th>
      <th>S</th>
      <th>Этажность</th>
      <th>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field ==='price' && (sort.value==='ASC' || sort.value==='DESC')"
             (click)="changeSort('price', 'DESC')">
          <i class="fas fa-sort icon-color"></i>$
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='price' || (sort.field==='price' && sort.value==='ASC')"
             (click)="changeSort('price', 'ASC')">
          <i class="fas fa-sort-down icon-color"></i>$
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='price' || (sort.field==='price' && sort.value==='DESC')"
             (click)="changeSort('price', 'DESC')">
          <i class="fas fa-sort-up icon-color"></i>$
        </div>
      </th>
      <th>$м2</th>
      <th *ngIf="user.access===4">Агентство</th>
      <th *ngIf="user.access!==4">Сотрудник</th>
      <th *ngIf="access.houses_created">
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field ==='created_at' && (sort.value==='ASC' || sort.value==='DESC')"
             (click)="changeSort('created_at', 'DESC')">
          <i class="fas fa-sort icon-color"></i>Дата доб.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='created_at' || (sort.field==='created_at' && sort.value==='ASC')"
             (click)="changeSort('created_at', 'ASC')">
          <i class="fas fa-sort-down icon-color"></i>Дата доб.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='created_at' || (sort.field==='created_at' && sort.value==='DESC')"
             (click)="changeSort('created_at', 'DESC')">
          <i class="fas fa-sort-up icon-color"></i>Дата доб.
        </div>
      </th>
      <th>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field ==='updated_at' && (sort.value==='ASC' || sort.value==='DESC')"
             (click)="changeSort('updated_at', 'DESC')">
          <i class="fas fa-sort icon-color"></i>Дата обн.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='updated_at' || (sort.field==='updated_at' && sort.value==='ASC')"
             (click)="changeSort('updated_at', 'ASC')">
          <i class="fas fa-sort-down icon-color"></i>Дата обн.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='updated_at' || (sort.field==='updated_at' && sort.value==='DESC')"
             (click)="changeSort('updated_at', 'DESC')">
          <i class="fas fa-sort-up icon-color"></i>Дата обн.
        </div>
      </th>
      <th>Действия</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let house of houses | paginate: { itemsPerPage: limit, currentPage: page, totalItems: countHouses }; let i = index">
      <tr>
        <td class="text-center">
          <a *ngIf="house.access_edit" routerLink="house/{{house.id}}" target="_self">
            <i class="fas fa-pencil-alt icon-color"></i>
          </a>
        </td>
        <td class="text-center">
          <span (click)="hideme[i] = !hideme[i]">
            <mat-icon class="icon-color size-18 icon-cursor" [hidden]="hideme[i]">keyboard_arrow_down</mat-icon>
            <mat-icon class="icon-color size-18 icon-cursor" [hidden]="!hideme[i]">keyboard_arrow_up</mat-icon>
          </span>
        </td>
        <td class="text-center">
          <label class="form-check-label check">
            <input class="checkbox" type="checkbox" id="{{house.id}}" (change)="hideme2[i] = !hideme2[i]">
            <i class="fas fa-check icon-color" [hidden]="!hideme2[i]"></i>
          </label>
        </td>
        <td>{{house.type.title}}</td>
        <td>{{house.location.city.district_country.region.title}}</td>
        <td>
          <ng-container *ngIf="house.location.city.district_country.region.id === 5 ">
            {{house.location.direction.small_title}}
          </ng-container>
          <ng-container *ngIf="house.location.city.district_country.region.id !== 5 ">
            {{house.location.city.district_country.title}}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="house.location.city.title.length >0 ">
            {{house.location.city.title}}
          </ng-container>
          <ng-container *ngIf="house.location.street.title.length >0 ">
            <br/> {{house.location.street.title}}
          </ng-container>
          <ng-container *ngIf="house.location.house !== '0' && house.location.house !== ''">
            ,{{house.location.house}}
          </ng-container>
          <ng-container *ngIf="house.location.housing !== '0' && house.location.housing !== ''">
            / {{house.location.housing}}
          </ng-container>
        </td>
        <td>{{house.roof.small_title}}</td>
        <td>{{house.area}}/{{house.area_leave}}/{{house.area_kitchen}}</td>
        <td>{{house.storeys}}</td>
        <td>{{house.price}}</td>
        <td>{{house.price_sqr}}</td>
        <td class="user_information">
          <span *ngIf="house.user.partner && user.partner" class="partner">
            Партнерские продажи
          </span>
          <span *ngIf="user.access===4" [ngClass]="{'bold': house.user.partner, 'cursor': house.user.partner}"> {{house.company.title}}</span>
          <span *ngIf="user.access!==4" [ngClass]="{'bold': house.user.partner, 'cursor': house.user.partner}"> {{house.user.user_information.surname}} {{house.user.user_information.name}}</span>
        </td>
        <td *ngIf="access.houses_created">
          {{house.created_at | date: 'dd.MM.yyyy'}}
        </td>
        <td>
          {{house.updated_at | date: 'dd.MM.yyyy'}}
        </td>
        <td>
          <i class="fas fa-chevron-circle-right icon-color icon-cursor" style="font-size: 20px;"
             (click)="close_hideme3(i);"></i>
          <div class="action-window" [hidden]="!hideme3[i]">
            <div *ngIf="house.access_edit" class="action" (click)="openDialog(house)">УДАЛИТЬ ОБЪЕКТ</div>
            <div class="action">ПОДБОР ПОКУПАТЕЛЕЙ</div>
            <div *ngIf="house.access_reclame" class="action" (click)="openDialogReclame(house)">УПРАВЛЕНИЕ РЕКЛАМОЙ</div>
            <div class="action" (click)="openCalculator(house)">КАЛЬКУЛЯТОР</div>
            <div class="action">
              <a class="action_href" [href]=viber_text[i]>ОТПРАВИТЬ НА VIBER</a>
            </div>
          </div>
        </td>
      </tr>
      <tr [hidden]="!hideme[i]">
        <td colspan="16">
          <div class="row no-gutters text-left">
            <div class="col-lg-6 col-md-12">
              <app-house-list-photo [photo]="house.photo"></app-house-list-photo>
            </div>
            <div class="col-lg-5 col-md-12" style="margin: 10px 0 0 10px">
               <ng-container *ngIf="user.access === 4 ">
                <span *ngIf="house.company.company_information.phone_house">
                  <ng-container *ngIf="house.company.company_information.phone_house_general_1 && house.company.company_information.phone_house_general_1!='375'"><b>{{house.company.company_information.phone_house_general_1}}</b></ng-container>
                  <ng-container *ngIf="house.company.company_information.phone_house_general_2 && house.company.company_information.phone_house_general_2!='375'">, <b>{{house.company.company_information.phone_house_general_2}}</b></ng-container>
                  <ng-container *ngIf="house.company.company_information.phone_house_general_3 && house.company.company_information.phone_house_general_3!='375'">, <b>{{house.company.company_information.phone_house_general_3}}</b></ng-container>
                  <ng-container *ngIf="house.company.company_information.phone_house_general_4 && house.company.company_information.phone_house_general_4!='375'">, <b>{{house.company.company_information.phone_house_general_4}}</b></ng-container>
                </span>
                <span *ngIf="!house.company.company_information.phone_house">
                  <ng-container *ngIf="house.user.user_information.phone1 && house.user.user_information.phone1!='375'"><b>{{house.user.user_information.phone1}}</b></ng-container>
                  <ng-container *ngIf="house.user.user_information.phone2 && house.user.user_information.phone2!='375'">, <b>{{house.user.user_information.phone2}}</b></ng-container>
                </span>
              </ng-container>
              <ng-container *ngIf="user.access !== 4 ">
              <span *ngIf="house.access_own_info">
                <ng-container *ngIf="house.cont_phone1 && house.cont_phone1!='375'"><b>{{house.cont_phone1}}</b></ng-container>
                <ng-container *ngIf="house.cont_phone2 && house.cont_phone2!='375'">, <b>{{house.cont_phone2}}</b></ng-container>
              </span>
                <span *ngIf="!house.access_own_info">
                  <ng-container *ngIf="house.user.user_information.phone1 && house.user.user_information.phone1!='375'"><b>{{house.user.user_information.phone1}}</b></ng-container>
                  <ng-container *ngIf="house.user.user_information.phone2 && house.user.user_information.phone2!='375'">, <b>{{house.user.user_information.phone2}}</b></ng-container>
                </span>
              </ng-container>
              <br>
              <span>{{house.text}}</span>
              <br>
              <a *ngIf="house.access_edit" class="btn btn-sm btn-primary" routerLink="/houses/house/{{house.id}}">Подробнее</a>
             <!-- <a class="btn">
                <mat-icon class="size-16 icon-color">place Смотреть на карте</mat-icon>
              </a>-->
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<div class="row no-gutters">
  <!--Пагинация -->
  <div class="col-12 text-center">
    <pagination-controls (pageChange)="getHousesByPage($event)" maxSize="7" previousLabel="" nextLabel=""
                         directionLinks="true" autohide="true"></pagination-controls>
  </div>
</div>

