<div class="container">
  <h3 style="margin-bottom: 40px">
    <div *ngIf="company.id;then edit else create"></div>

    <ng-template #create>Добавление компании</ng-template>
    <ng-template #edit> Редактирование компании</ng-template>

  </h3>
  <div class="row">
    <div class="col-6 col-6">
      <h4>Основная информация</h4>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Название<span
          style="color: red">*</span></label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.title" (change)="validationTitle()">
          <small *ngIf="validation1 === false" class="form-text text-danger">{{message1}}</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Название организации</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.name_organization">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">ФИО директора</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.fio_director">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Телефон директора</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.phone_director">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Лицензия</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.license">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Выдана</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.license_issued">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">От</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.license_from"
                 ngbDatepicker (click)="license_from.toggle()" #license_from="ngbDatepicker">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">До</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.license_to"
                 ngbDatepicker (click)="license_to.toggle()" #license_to="ngbDatepicker">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Префикс</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.prefix">
        </div>
      </div>
    </div>
    <div class="col-6 col-6">
      <h4>Дополнительная информация</h4>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">ID домовиты</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.id_domovita">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Логин онлайнер</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.login_onliner">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Пароль онлайнер</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.password_onliner">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Логин реалт</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.login_realt">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Пароль реалт</label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.password_realt">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12">
          <a class="btn" target="_blank" routerLink="/companies/company/{{company.id}}">Перейти на страницу настроек компании</a>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="modules" class="row">
    <div class="col-6 col-6">
      <h4>Модули</h4>
      <div *ngFor="let module of modules" class="form-check">
        <input class="form-check-input" type="checkbox" id="{{ module.id}}"
               [checked]="company.modules[module.id]"
               (change)="company.modules[module.id] = !company.modules[module.id]">
        <label class="form-check-label">
          {{ module.title}}
        </label>
      </div>
    </div>
  </div>

  <div [ngStyle]="{'display' : !company.id ? 'block' : 'none' }" class="row">
    <div class="col-12 col-12">
      <h4>Сотрудник</h4>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Имя сотрудника<span
          style="color: red">*</span></label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.user.user_information.name" (change)="validationNameUser()">
          <small *ngIf="validation2 === false" class="form-text text-danger">{{message2}}</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Логин сотрудника<span
          style="color: red">*</span></label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.user.login" (change)="validationLoginUser()">
          <small *ngIf="validation3 === false" class="form-text text-danger">{{message3}}</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">Пароль сотрудника<span
          style="color: red">*</span></label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.user.password_first" (change)="validationPasswordUser()">
          <small *ngIf="validation4 === false" class="form-text text-danger">{{message4}}</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label text-left">ID сотрудника для REALT.BY<span
          style="color: red">*</span></label>
        <div class="col-7">
          <input type="text" class="form-control" [(ngModel)]="company.company_information.id_user_realt">
          <!--<small *ngIf="validation4 === false" class="form-text text-danger">{{message4}}</small>-->
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-12">
    <button (click)="save()" class="btn btn-lg btn-info">
      Сохранить
    </button>
  </div>
</div>
