<div class="container" style="margin-top: 100px">
  <div class="row">

    <div class="col-md-6 col-sm-6 col-md-offset-3">
      <form class="form" role="form" method="POST" action="">
        <h5 class="text-danger" *ngIf="message.length > 0">{{message}}</h5>
        <div class="form-group">
          <label for="login" class="col-md-4 control-label">Логин</label>

          <div class="col-md-6">
            <input id="login" type="text" class="form-control" name="login" [(ngModel)]="login">
          </div>
        </div>

        <div class="form-group">
          <label for="password" class="col-md-4 control-label">Пароль</label>

          <div class="col-md-6">
            <input id="password" type="password" class="form-control" name="password" [(ngModel)]="password">
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-8 col-md-offset-8">
            <button type="submit" class="btn btn-primary" (click)="logIn();">
              Войти
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
