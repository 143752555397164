
export class SiteModel {

  constructor(
    public id: number,
    public title: string,
    public block: boolean,
    public key_site: number
  ) {}
}
