<div class="container">
  <h5 style="color: #428bca!important;">Отправка объектов в рекламу</h5>

  <table class="table table-bordered">
    <tr *ngFor="let site of data.sites">
      <td>
        <div class="checkbox">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="{{ site.id}}"
                   [checked]="data.house.arraySites[site.id]"
                   (change)="data.house.arraySites[site.id] = !data.house.arraySites[site.id]">
            <label class="form-check-label">
              {{ site.title}}
            </label>
          </div>
        </div>
      </td>
      <td style="min-width: 200px"></td>
    </tr>
  </table>

  <mat-dialog-actions>
    <button style="background: #cee0ec" mat-button (click)="onNoClick()">Отмена</button>
    <button style="background: #cee0ec" mat-button [mat-dialog-close]="data.house" cdkFocusInitial>Сохранить</button>
  </mat-dialog-actions>

</div>
