export class Source {

  constructor(
    public id: number,
    public title: string,
    public priority: number,
    public count: string,
    public persent: string
  ) {}
}
