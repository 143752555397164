<ul class="nav nav-tabs navbar-dark bg-dark" style="color: white">
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/companies">Компании</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/modules">Модули</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/users">Сотрудники</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/roles">Привилегии</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/sites">Сайты</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/sources">Источники</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin/locations">Адреса</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="logout()">Выйти</a>
  </li>
</ul>



