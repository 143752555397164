<div class="container bg-light" style="max-width: 100%;">

  <div class="row search-top" [ngClass]="{'show' : !hide, 'hide' : hide}">
    <div class="col-lg-5 my-col-md-12 my-col-sm-12 ">
      <div class="row">
        <div class="col-6 my-col-sm-12">
          <div class="form-group">
            <ng-select [options]="districts_rb"
                       [(ngModel)]="search.districts_country.values"
                       [multiple]="true"
                       placeholder="Район РБ"
                       (selected)="selectCities($event);">
            </ng-select>
          </div>
        </div>
        <div class="col-6 my-col-sm-12">
          <div class="form-group">
            <ng-select [options]="cities"
                       [(ngModel)]="search.cities.values"
                       [multiple]="true"
                       (filterInputChanged)="getCities(0, this.districtsRbSearch, $event)"
                       notFoundMsg="Введите не менее 3-х символов"
                       placeholder="Населенный пункт">
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 my-col-md-7 my-col-sm-12">

        <span class="labels-lg">
            <ng-container *ngFor="let type of types">
                <label class="cursor"
                       (click)="typesTrigger(type.bit_mask); arrayTypes[type.bit_mask] = !arrayTypes[type.bit_mask]"
                       [ngClass]="{'select-label' : arrayTypes[type.bit_mask]}">
                  <span>{{type.title}}</span>
                </label>
              </ng-container>
        </span>
      <span class="text-right">
          <input type="text" class="form-control input-mini" [(ngModel)]="search.price" placeholder="Стоимость">
        </span>
    </div>

    <div class="col-lg-3 my-col-md-5 my-col-sm-12 my-sol-xs-12 text-right padding-left"
         style="color: #428bca">
      <a class="btn" (click)="hide=!hide">
        <i class="fas fa-search-plus"></i>
        <span>Расширенный поиск</span>
      </a>

      <button class="btn btn-sm btn-primary" (click)="searchClients()">Поиск</button>
    </div>
  </div>

  <div class="row search-top" [ngClass]="{'show' : hide, 'hide' : !hide}">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <ng-select [options]="regions"
                       [(ngModel)]="search.regions.values"
                       [multiple]="true"
                       placeholder="Область"
                       (selected)="selectDistrictsRb($event);">
            </ng-select>
          </div>

          <div class="form-group">
            <ng-select [options]="districts_rb"
                       [(ngModel)]="search.districts_country.values"
                       [multiple]="true"
                       placeholder="Район РБ"
                       (selected)="selectCities($event);">
            </ng-select>
          </div>

          <div class="form-group">
            <ng-select [options]="directions"
                       [(ngModel)]="search.directions.values"
                       [multiple]="true"
                       placeholder="Направление">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group">
            <ng-select [options]="cities"
                       [multiple]="true"
                       [(ngModel)]="search.cities.values"
                       placeholder="Нас. пункт"
                       (selected)="selectDistrict($event)"
                       (deselected)="deselectDistrict($event)"
                       (filterInputChanged)="getCities(0, this.districtsRbSearch, $event)"
                       notFoundMsg="Введите не менее 3-х символов">
            </ng-select>
          </div>

          <div class="form-group">
            <ng-select [options]="districts"
                       [multiple]="true"
                       [(ngModel)]="search.districts.values"
                       placeholder="Район">
            </ng-select>
          </div>

          <div class="form-group">
            <div class="search search-full">
              <label class="label text-left">Удаление от МКД</label>
              <input type="text" placeholder="" [(ngModel)]="search.mcad">
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-2 col-sm-2 col-12">
          <div class="form-group">
            <div class="check">
              <label class="btn btn-default" (click)="search.partner = !search.partner"
                     [ngClass]="{'select-label' : search.partner}">
                <span>Партнеры</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-10 col-12">
          <span class="labels-lg">
            <ng-container *ngFor="let type of types">
                <label class="cursor"
                       (click)="typesTrigger(type.id); arrayTypes[type.id] = !arrayTypes[type.id]"
                       [ngClass]="{'select-label' : arrayTypes[type.id]}">
                  <span>{{type.title}}</span>
                </label>
            </ng-container>
          </span>
        </div>

        <div class="col-md-4 col-12">
          <div class="form-group">
            <div class="search">
              <label class="label text-left">Стоимость</label>
              <input type="text" placeholder="" [(ngModel)]="search.price">
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <div class="search search-mini">
              <label class="label text-left">Дата пост.</label>
              <input type="text" placeholder="от" [(ngModel)]="search.created_from"
                     ngbDatepicker (click)="created_from2.toggle()" #created_from2="ngbDatepicker">
              <input type="text" placeholder="до" [(ngModel)]="search.created_to"
                     ngbDatepicker (click)="created_to2.toggle()" #created_to2="ngbDatepicker">
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-12">
          <div class="form-group">
            <div class="search search-mini">
              <label class="label text-left">Дата обн.</label>
              <input type="text" placeholder="от" [(ngModel)]="search.updated_from"
                     ngbDatepicker (click)="updated_from2.toggle()" #updated_from2="ngbDatepicker">
              <input type="text" placeholder="до" [(ngModel)]="search.updated_to"
                     ngbDatepicker (click)="updated_to2.toggle()" #updated_to2="ngbDatepicker">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="row" style="color: #428bca" [ngClass]="{'show' : hide, 'hide' : !hide}">
    <div class="col-sm-3 col-3">
      <a style="text-decoration: underline" class="btn" (click)="clear()">Очистить <span
        class="my-col-xs-none">поиск</span></a>
    </div>
    <!--    <div class="col-sm-2 text-center">
          <button style="margin-left: 10px" class="btn btn-md btn-primary" (click)="searchClients()">Поиск</button>
        </div>-->

    <div class="col-sm-9 col-9 text-right padding">
      <button style="margin-right: 10px" class="btn btn-sm btn-primary" (click)="searchClients()">Поиск</button>
      <a style="cursor: pointer; text-decoration: underline" (click)="hide=!hide">
        <i class="fas fa-search-minus"></i>
        <!--<mat-icon style="vertical-align: middle; font-size: 20px">search</mat-icon>-->
        Скрыть <span class="my-col-xs-none">поиск</span></a>
      <a style="cursor: pointer; margin-left: 15px; text-decoration: underline">Сохранить <span class="my-col-xs-none">поиск</span></a>
    </div>

  </div>
</div>

