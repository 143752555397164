import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.css']
})
export class Error403Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
