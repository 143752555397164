export class SaleAdditionInformation {
        constructor(
            public id: number,
            public phone: boolean,
            public internet: boolean,
            public tv: boolean,
            public intercom: boolean,
            public video_intercom: boolean,
            public cctv: boolean,
            public signaling: boolean,
            public concierge: boolean,
            public door: boolean,
            public elevator: boolean,
            public chute: boolean,
            public single_enter: boolean,
            public garage: boolean,
            public parking: boolean,
            public protected_parking: boolean,
            public cupboard: boolean,
            public appliances: boolean,
            public dishwasher: boolean,
            public washer: boolean,
            public fridge: boolean,
            public conditioner: boolean,
            public fireplace: boolean,
            public basement: boolean,
            public pool: boolean,
            public jacuzzi: boolean,
            public water_meters: boolean,
            public sanitary: boolean,
            public window: boolean,
        ) {}
}
