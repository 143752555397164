<div class="table-responsive">
  <table class="table table-bordered table-hover">
    <thead>
    <tr style="background:#cccccc" >
      <th>Дата</th>
      <th>Всего</th>
      <th *ngFor="let source of sources">{{source.title}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let statistic of statistics">
      <td>{{statistic.date}}</td>
      <td>{{statistic.all_count}}</td>
      <td *ngFor="let source of statistic.sources">{{source.count}}<br>{{source.persent}}%</td>
    </tr>
    </tbody>
  </table>
</div>
