<div class="container">
  <div class="newRow">
    <div class="row">
      <div class="col-12">
        <h3>Привилегии</h3>
        <div class="row">
          <div class="col-4">
            Имя роли
          </div>
          <div class="col-8">
            <div class="form-group label">
              <input class="form-control" type="text" placeholder=" " [(ngModel)]="role.title">
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let permission of permissions | filter">

          <div class="col-4">
            <span class="category" [ngClass]="{'category_main' : permission.main}">{{permission.title}}</span>
          </div>

          <div class="col-md-5 col-8">
            <ng-container *ngIf="provePermission(permission.id)">
              <select class="form-control" (change)="onChange($event.target.value, permission.permissions)">
                <option [value]="0"></option>
                <option *ngFor="let item of permission.permissions" [value]="item.id" [selected]="role.array_permissions[item.id].check">{{item.title}}</option>
              </select>
            </ng-container>

            <ng-container *ngIf="!provePermission(permission.id)">
            <div class="form-check {{role.array_permissions[item.id].label ? 'form-check-inline' : 'form-check' }}"
                 *ngFor="let item of permission.permissions">

              <input class="form-check-input" type="checkbox" id="{{ item.id}}"
                     [checked]="role.array_permissions[item.id].check"
                     (change)="role.array_permissions[item.id].check = !role.array_permissions[item.id].check">
              <label class="form-check-label" (click)="role.array_permissions[item.id].check = !role.array_permissions[item.id].check">
                {{ item.title}}
              </label>
            </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="myButton">
        <div class="col-12">
          <button (click)="save()" class="btn btn-lg btn-info">
            Сохранить
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
