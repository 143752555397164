<div class="row no-gutters">

  <div style="width: 160px">
    <a href="" style="outline:none;">
      <img style="margin: 4px 10px;" class="img img-fluid" src="assets/images/belan-logo_mini2.png"/>
    </a>
  </div>

<!--  <div class="col-lg-2 col-md-2 col-sm-3 col-7">
    <a href="" style="outline:none;">
      <img style="margin: 4px 10px;" class="img img-fluid" src="assets/images/belan-logo_mini2.png"/>
    </a>
  </div>-->
  <div class="display">
    <div class="row no-gutters">
     <!-- <div class="my-col-sm-3 d-none">
        <span class="cursor" (click)="hide_info=!hide_info"><i class="fas fa-align-justify"></i></span>
        <div class="menu" [ngClass]="{'show' : hide_info, 'hide' : !hide_info}">
          <a class="dropdown-item">1</a>
          <a class="dropdown-item">2</a>
          <a class="dropdown-item">3</a>
        </div>
      </div>-->
      <div class="col-lg-9 col-md-8 d-none d-md-block">
        <!--Меняющийся навбар -->
        <nav class="navbar navbar-expand-lg">

          <ul class="navbar-nav">
            <!-- Меню для SALES-->
            <ng-container *ngIf="path==='sales'">
              <li *ngIf="access.sales_create" class="nav-item">
                <a class="nav-link" routerLink="/sales/sale">Добавить</a>
              </li>
              <li *ngIf="access.sales_archive" class="nav-item">
                <a class="nav-link" routerLink="/sales/archive">Архив</a>
              </li>
              <li *ngIf="access.sales_moderation" class="nav-item">
                <a class="nav-link" routerLink="/sales/delete">Удаленные
                  <span *ngIf="count_delete_sale" class="icon-moderation">
                    +{{count_delete_sale}}
                  </span>
                </a>
              </li>
              <li *ngIf="access.sales_moderation" class="nav-item">
                <a class="nav-link" routerLink="/sales/moderation">Модерация
                  <span *ngIf="count_moderation_sale" class="icon-moderation">
                    +{{count_moderation_sale}}
                  </span>
                </a>
              </li>
             <!-- <li class="nav-item">
                <a class="nav-link" routerLink="/sales/hhos">Хозяйские</a>
              </li>-->
              <!--<li class="nav-item">
                <a class="nav-link" routerLink="#">Карта</a>
              </li>-->
            </ng-container>
            <!-- Конец меню для SALES-->

            <!-- Меню для CLIENTS-->
            <ng-container *ngIf="path==='clients' || path==='calls'">
              <li *ngIf="access.clients_create" class="nav-item">
                <a class="nav-link" routerLink="/clients/client">Добавить</a>
              </li>
              <li *ngIf="access.clients_archive" class="nav-item">
                <a class="nav-link" routerLink="/clients/archive">Архив</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/calls">Звонки</a>
              </li>
              <li *ngIf="access.statistics_call" class="nav-item">
                <a class="nav-link" routerLink="/calls/statistics">Статистика</a>
              </li>
            </ng-container>
            <!-- Конец меню для CLIENTS-->

            <!-- Меню для HOUSES-->
            <ng-container *ngIf="path==='houses'">
              <li *ngIf="access.houses_create" class="nav-item">
                <a class="nav-link" routerLink="/houses/house">Добавить</a>
              </li>
              <li *ngIf="access.houses_archive" class="nav-item">
                <a class="nav-link" routerLink="/houses/archive">Архив</a>
              </li>
              <li *ngIf="access.houses_moderation" class="nav-item">
                <a class="nav-link" routerLink="/houses/delete">Удаленные
                  <span *ngIf="count_delete_house" class="icon-moderation">
                    +{{count_delete_house}}
                  </span>
                </a>
              </li>
              <li *ngIf="access.houses_moderation" class="nav-item">
                <a class="nav-link" routerLink="/houses/moderation">Модерация
                  <span *ngIf="count_moderation_house" class="icon-moderation">
                    +{{count_moderation_house}}
                  </span>
                </a>
              </li>
            </ng-container>
            <!-- Конец меню для HOUSES-->

            <!-- Меню для CLIENTS-HOUSE-->
            <ng-container *ngIf="path==='clients_house' || path==='calls_house'">
              <li *ngIf="access.clients_houses_create" class="nav-item">
                <a class="nav-link" routerLink="/clients_house/client">Добавить</a>
              </li>
              <li *ngIf="access.clients_houses_archive" class="nav-item">
                <a class="nav-link" routerLink="/clients_house/archive">Архив</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/calls_house">Звонки</a>
              </li>
              <li *ngIf="access.statistics_call" class="nav-item">
                <a class="nav-link" routerLink="/calls_house/statistics">Статистика</a>
              </li>
            </ng-container>
            <!-- Конец меню для CLIENTS-HOUSE-->

            <!-- Меню для USERS-->
            <ng-container *ngIf="path==='users'">
              <li class="nav-item">
                <a class="nav-link" routerLink="/users/user">Добавить</a>
              </li>
            </ng-container>
            <!-- Конец меню для USERS-->

            <!-- Меню для ROLES-->
            <ng-container *ngIf="path==='roles'">
              <li class="nav-item">
                <a class="nav-link" routerLink="/roles/role">Добавить</a>
              </li>
            </ng-container>
            <!-- Конец меню для ROLES-->

          </ul>
        </nav>
      </div>

      <!-- Информация о пользователе -->
      <div class="col-lg-3 col-md-4 col-9 user">
        <app-menu-user-info></app-menu-user-info>
      </div>
    </div>
  </div>


</div>


