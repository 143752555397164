<div class="container-fluid">

  <div class="row">
    <div class="col-12 col-12">
      <h3 style="margin-bottom: 40px">
        Добавление адреса
        <!--<div *ngIf="location.id;then edit else create"></div>-->

        <ng-template #create>Добавление адреса</ng-template>
        <ng-template #edit>Редактирование адреса</ng-template>

      </h3>
      <form [formGroup]="locationForm" (submit)="onSubmit()">
        <div class="row no-gutters">

          <div class="col-lg-4">
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input type="text" placeholder="Область" aria-label="Область" matInput
                         formControlName="region" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="valueRegion"
                                    (optionSelected)="getDistrict($event.option.value)">
                    <mat-option *ngFor="let option of filteredRegions | async" [value]="option">
                      {{option.title}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input type="text" placeholder="Район РБ" aria-label="Район РБ" matInput
                         formControlName="district_country" [matAutocomplete]="auto2">
                  <mat-autocomplete #auto2="matAutocomplete" [displayWith]="valueDistrict"
                                    (optionSelected)="getCity($event.option.value)">
                    <mat-option *ngFor="let option of filteredDistricts | async" [value]="option">
                      {{option.title}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input type="text" placeholder="Населенный пункт" aria-label="Населенный пункт" matInput
                         formControlName="city" [matAutocomplete]="auto3" (change)="getStreet(0)">
                  <mat-autocomplete #auto3="matAutocomplete" [displayWith]="valueCity"
                                    (optionSelected)="getStreet($event.option.value);getLocation($event.option.value)"
                                    (closed)="clear()">
                    <mat-option *ngFor="let option of filteredCities | async" [value]="option">
                      {{option.title}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input type="text" placeholder="Улица" aria-label="Улица" matInput
                         formControlName="street" [matAutocomplete]="auto4">
                  <mat-autocomplete #auto4="matAutocomplete" [displayWith]="valueStreet"
                                    (optionSelected)="getInfoLocation()">
                    <mat-option *ngFor="let option of filteredStreets | async" [value]="option">
                      {{option.title}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-4">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="location.house" formControlName="house" matInput placeholder="Номер дома"
                         (change)="getInfoLocation()" (keypress)="keyPress($event);">
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="location.housing" formControlName="housing" matInput placeholder="Корпус"
                         (change)="getInfoLocation()">
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <mat-select formControlName="direction" [(ngModel)]="location.direction.id" placeholder="Направление">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let one of directions" [value]="one.id">{{one.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>


          <div class="col-lg-4">
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <h5> Только для раздела Продажи</h5>
                <mat-form-field class="full-width">
                  <mat-select formControlName="district" [(ngModel)]="location.district.id" placeholder="Район">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let one of districts" [value]="one.id">{{one.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <mat-select formControlName="microdistrict" [(ngModel)]="location.microdistrict.id" placeholder="Микрорайон">
                    <mat-option></mat-option>
                   <mat-option *ngFor="let one of microdistricts" [value]="one.id">{{one.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <mat-select formControlName="wall" [(ngModel)]="location.wall" placeholder="Материал стен">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let one of walls" [value]="one.id">{{one.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <mat-select formControlName="type_house" [(ngModel)]="location.type_house" placeholder="Тип дома">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let one of types" [value]="one.id">{{one.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="location.year" formControlName="year" matInput placeholder="Год постройки">
                </mat-form-field>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <div class="col-7">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="location.year_repair" formControlName="year_repair" matInput
                         placeholder="Год капремонта">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-lg-3" *ngIf="regions.length > 0 && districts_country.length > 0 && cities.length > 0" style="height: 600px; overflow-x: auto">
            <app-admin-request-location [location]="[regions, districts_country, cities, streets]" [request]="request" [deleteRequest]="deleteRequest" (changed)="getRequest($event)"></app-admin-request-location>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="form-group">
            <div class="col-4 offset-4">
              <button type="submit" class="btn btn-lg btn-info">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

