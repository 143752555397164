<h2 mat-dialog-title>
  <i class="fa fa-check-circle" style="color: #ff5619; font-size: 30px;margin-right: 5px"></i>
  Напишите причину блокировки!</h2>
<mat-dialog-content>
  <textarea style="width: 100%" class="form-control" [(ngModel)]="data.company.company_history_blocks"></textarea>
</mat-dialog-content>
<mat-dialog-actions>
  <button style="background: #cee0ec" mat-button (click)="onNoClick()">Отмена</button>
  <button style="background: #cee0ec" mat-button [mat-dialog-close]="data.company" cdkFocusInitial>ОК</button>
</mat-dialog-actions>
