<div class="left-menu">
  <div ngbDropdown class="main-item">
    <a class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <i class="fas fa-key"></i>
      <span>Объекты</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <a *ngIf="access.sales" class="dropdown-item" routerLink="/sales">Квартиры</a>
      <a *ngIf="access.houses" class="dropdown-item" routerLink="/houses">Дома, дачи</a>
      <!--<a class="dropdown-item" routerLink="/sales">Аренда</a>
      <a class="dropdown-item" routerLink="/sales">Коммерческая</a>
      <a class="dropdown-item" routerLink="/sales">Заявки на подбор</a>-->
    </div>
  </div>

  <div ngbDropdown class="main-item">
    <a class="btn" id="dropdownBasic2" ngbDropdownToggle>
      <i class="fas fa-user"></i>
      <span>Клиенты</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
      <a *ngIf="access.clients" class="dropdown-item" routerLink="/clients">Квартиры</a>
      <a *ngIf="access.clients_houses" class="dropdown-item" routerLink="/clients_house">Дома, дачи</a>
      <!--<a class="dropdown-item" routerLink="/sales">Аренда</a>
      <a class="dropdown-item" routerLink="/sales">Коммерческая</a>
      <a class="dropdown-item" routerLink="/sales">Предложения <br/>покупателям</a>-->
    </div>
  </div>

  <div *ngIf="access.users"ngbDropdown class="main-item">
    <a class="btn" id="dropdownBasic3" ngbDropdownToggle>
      <i class="fas fa-users"></i>
      <span>Сотрудники</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
      <a class="dropdown-item" routerLink="/users">Агенты</a>
      <a *ngIf="access.roles" class="dropdown-item" routerLink="/roles">Права</a>
      <a *ngIf="user.partner" class="dropdown-item" routerLink="/partners">ПП</a>
      <a class="dropdown-item" routerLink="/calls_other">Звонки</a>
    </div>
  </div>

  <!-- Если есть доступ к Права или ПП и нет к Сотрудники-->
  <ng-container *ngIf="!access.users">
    <div class="main-item">
      <a *ngIf="access.roles" class="btn" routerLink="/roles">
        <i class="fas fa-users"></i>
        <span>Права</span>
      </a>
    </div>
    <div class="main-item">
      <a *ngIf="user.partner" class="btn" routerLink="/partners">
        <i class="fas fa-users"></i>
        <span>ПП</span>
      </a>
    </div>
    <div class="main-item">
      <a class="btn" routerLink="/calls_other">
        <i class="fas fa-users"></i>
        <span>Звонки</span>
      </a>
    </div>
  </ng-container>

  <div class="main-item">
    <a *ngIf="access.settings" class="btn" routerLink="/companies/company/{{user.company.id}}">
      <i class="fas fa-cogs"></i>
      <span>Настройки</span>
    </a>
  </div>
</div>
