<div class="sticky-top">
  <div class="row no-gutters">
    <div class="col-12 general-menu">
      <app-general-menu></app-general-menu>
    </div>
  </div>
</div>
<div class="row no-gutters">
  <div class="left-sidebar d-none d-md-block">
    <app-general-left-menu></app-general-left-menu>
  </div>
  <div class="display">
    <router-outlet></router-outlet>
  </div>
</div>
