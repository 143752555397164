<div class="table-responsive" style="margin-bottom: 60px">

  <div>
    <button class="btn btn-primary" (click)="add()">Добавить</button>
  </div>

  <table class="table table-bordered table-striped tableSticky">
    <thead>
    <tr style="background:#cccccc">
      <th></th>
      <th>Фамилия</th>
      <th>Имя</th>
      <th>Отчество</th>
      <th>Компания</th>
      <th>Телефон</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td class="align-left">
        <a routerLink="user/{{user.id}}" target="_self">
          <img src="assets/images/edit.jpg" style="cursor:pointer" alt="Редактировать" title="Редактировать">
        </a>
      </td>
      <td class="align-left">{{user.user_information.surname}}</td>
      <td class="align-left">{{user.user_information.name}}</td>
      <td class="align-left">{{user.user_information.patronymic}}</td>
      <td class="align-left">{{user.company.title}}</td>
      <td class="align-left">{{user.user_information.phone1}}</td>
      <td>
        <!--[ngClass]="{'color-btn':user.ban}"-->
        <button  class="btn btn-sm btn-danger {{user.ban ? 'd-none' : '' }}"
                (click)="ban(user)">Забанить
        </button>
        <button class="btn btn-sm btn-primary {{!user.ban ? 'd-none' : '' }}"
                (click)="unban(user)">Восстановить
        </button>
      </td>
      <td>
        <button class="btn btn-danger" (click)="delete(user)">Удалить</button>
      </td>
    </tr>
    </tbody>
  </table>

</div>

<div class="container-fluid" style="bottom: 0;position: fixed; width: 100%">
  <div class="row" style=" background: #ccc; padding: 5px">
    <div class="form-group row">
      <label for="phone" class="col-5 col-form-label text-left">Телефон</label>
      <div class="col-7">
        <input type="text" class="form-control" id="phone" name="phone" (change)="getUsersSearch()"
               [(ngModel)]="search.phone">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <select class="form-control" [(ngModel)]="search.company" (change)="getUsersSearch()">
          <option value="" [disabled]="true">Компания</option>
          <option value="0"></option>
          <option *ngFor="let one of companies" [value]="one.id">{{one.title}}</option>
        </select>
      </div>
    </div>
  </div>
</div>

