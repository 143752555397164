<app-calls-list-search (changed)="getCallsSearch($event)"></app-calls-list-search>

<div class="table-responsive" style="margin-bottom: 20px">
  <table class="table table-bordered table-hover">
    <thead>
    <tr>
      <th></th>
      <th>Комнат</th>
      <th>Цена</th>
      <th>Адрес</th>
      <th>Телефон</th>
      <th>Примечание</th>
      <th>Сотрудник</th>
      <th>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field ==='created_at' && (sort.value==='ASC' || sort.value==='DESC')"
             (click)="changeSort('created_at', 'DESC')">
          <i class="fas fa-sort icon-color"></i>Дата доб.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='created_at' || (sort.field==='created_at' && sort.value==='ASC')"
             (click)="changeSort('created_at', 'ASC')">
          <i class="fas fa-sort-down icon-color"></i>Дата доб.
        </div>
        <div class="nobr cursor" style="width: 100%"
             [hidden]="sort.field!=='created_at' || (sort.field==='created_at' && sort.value==='DESC')"
             (click)="changeSort('created_at', 'DESC')">
          <i class="fas fa-sort-up icon-color"></i>Дата доб.
        </div>
      </th>
      <th *ngIf="access.listen_call"></th>
      <th *ngIf="access.listen_call"></th>
      <th></th>
      <th *ngIf="access.source_call">Источник</th>
    </tr>
    </thead>
    <tbody>
    <ng-container
      *ngFor="let call of calls | paginate: { itemsPerPage: limit, currentPage: page, totalItems: countClients }; let i  = index">
      <tr>
        <td class="text-center">
          <a *ngIf="call.ready ===0" routerLink="call/{{call.id}}" target="_self">
            <i class="fas fa-user-plus icon-color"></i>
          </a>
        </td>
        <td>{{call.sale.room}}</td>
        <td>{{call.sale.price}}</td>
        <td>
          <ng-container *ngIf="call.sale.location.street.title.length >0 ">
            {{call.sale.location.street.title}},
          </ng-container>
          {{call.sale.location.house}}
          <ng-container *ngIf="call.sale.location.housing !== '0' && call.sale.location.housing !== ''">
            / {{call.sale.location.housing}}
          </ng-container>
        </td>
        <td>
          <span [innerHTML]="call.phone===null ? '' : call.phone | sanitizeHtml"></span><br/>
        </td>
        <td *ngIf="call.id == editRowId">
          <textarea rows="5" col="25" #myInput type="text" [(ngModel)]="call.description"
                    (blur)="change(call)"></textarea>
        </td>
        <td *ngIf="call.id !== editRowId">
          <i class="fas fa-pen icon-color cursor" (click)="toggle(call.id)"></i>
          {{call.description}}
        </td>
        <td>{{call.user.user_information.surname}} {{call.user.user_information.name}}</td>
        <td>
          {{call.created_at | date: 'dd.MM.yyyy HH:mm:ss'}}
        </td>
        <td *ngIf="access.listen_call">
            <a *ngIf="call.uid" target="_blank" href="http://pbx.virtual-office.by:20080/media/{{call.uid}}.wav"><i
              class="fas fa-headphones icon-color"></i></a>
        </td>
        <td *ngIf="access.listen_call">
            <a *ngIf="call.uid2" target="_blank" href="http://pbx.virtual-office.by:20080/media/{{call.uid2}}.wav"><i
              class="fas fa-headphones icon-color"></i></a>
        </td>
        <td>{{call.duration}}</td>
        <td *ngIf="access.source_call">
            {{call.source.title}}
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>


<div class="row no-gutters">
  <!--Пагинация -->
  <div class="col-12 text-center">
    <pagination-controls (pageChange)="getCallsByPage($event)" maxSize="7" previousLabel="" nextLabel=""
                         directionLinks="true" autohide="true"></pagination-controls>
  </div>
</div>


